import React from "react";
import Helmet from "react-helmet";
import "../assets/css/App.css";
import "../assets/css/index.css";
import { Button, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Img from "gatsby-image";
import SEO from "../components/SEO";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

const StyledButton = styled(Button)`
  & {
    margin: 0;
  }
`;

export default ({ data }) => (
  <Layout>
    <SEO
      title="Recently Completed Projects by"
      description="BBIS Heating have completed projects on some of the United Kingdom prised houses, working on commercial boiler service and domestic heating maintenance"
      keywords=""
    />
     <div class="white-segment text-left">
      <h1 class="heading-color">Our Recently Completed Commercial Heating Projects</h1>
      <h2 class="sub-heading pb-2">Marble House</h2>
      <Row>
        <Col lg>
          <Img
            fluid={data.newmarblehillhouse50.childImageSharp.fluid}
            className="pb-4 mx-auto d-block img-fluid"
            alt="BBIS-Portland-Place"
          />
          <h4 class="sub-heading pt-4">Project details</h4>
          <h5>Before</h5>
          <p>
          A dated boiler room installed in the 1970’s by the old GLC. Parts were obsolete for the ageing boilers and plant. The plant room was in dire need or modernisation.
          </p>
          <h5>After</h5>
          <p>The renovation project involved various tasks aimed at enhancing the boiler room. It includes repairing the existing floor, as well as painting the walls, floor, and ceiling to give the space an updated look. Additionally, the installation of 2 new high efficiency condensing boilers and stainless steel flues was carried out, ensuring improved efficiency and durability. The project also encompasses the installation of necessary pipework and fittings, guaranteeing a seamless and reliable system. To optimise functionality, automatic controls and a distribution panel were installed, enabling efficient management and regulation of the boiler room.
          </p>
          <StyledButton
            href="https://hdvirtualart.com/tours/bbis/002/"
            variant="primary"
            size="lg"
            target="_blank"
          >
            View 360 tour
          </StyledButton>
        </Col>
      </Row>
      <h4 class="sub-heading pt-4">Project images</h4>
        <br></br>
      <Row>

      <Col md className="pbs">
          <Img
            fluid={data.marblehouse.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>

        <Col md className="pbs">
          <Img
            fluid={data.marblehouse1.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.marblehouse2.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>

        <Col md className="pbs">
          <Img
            fluid={data.marblehouse3.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
      </Row>
      <br></br>
      <Row>
      <Col md className="pbs">
          <Img
            fluid={data.marblehouse4.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>

        <Col md className="pbs">
          <Img
            fluid={data.marblehouse5.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.marblehouse6.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
      </Row>
    </div>
    <div class="grey-segment text-left">
    <h4 class="sub-heading pb-2">Portland Place</h4>
    <Img
            fluid={data.portlandplace86.childImageSharp.fluid}
            className="pb-4 mx-auto d-block img-fluid"
            alt="BBIS-Portland-Place"
          />
      <Row>
        <Col lg>
          <h4 class="sub-heading pt-4">Project details</h4>
          <h5>Before</h5>
          <p>
            A boiler room which has had several updates and adaptions over the years. This boiler room has seen the transition from old coal boilers to oil boilers to gas boilers over the years. In Its latest incarnation some of the plant was 40 years old, relatively young to some of the pipes in the plant room that were 100 years old! The boilers it was operating were 25 years old, modern by these comparisons. 
            These boilers were decommissioned along with all plant and the existing pipework cut out as far as possible. This was of course after a thorough asbestos decontamination phase to remove all asbestos from the area.
            Before any new plant was installed the room was cut in to two to form our new plant room and a new cycle store with storage area. Floors were screeded, walls repaired, the rooms were painted top to bottom and new cupboards installed in the storeroom.
            The object here was to recover as much “dead space” as possible – the old boiler room occupied a large area and was an inefficient use of space.
          </p>
          <h5>
            After
          </h5>
        <p>
          The new plant room now comprises of 3 new A rated condensing gas boilers with a new flue system discharging high above the roof of the building. Plate heat exchangers were used to separate the old heating system from the new boilers. The latest technology in pumps were utilised to contribute to the overall energy efficiency of the system.
          The new plant and their energy saving systems have had a profound impact on the energy rating of the building. The super-efficient plant combined with the latest BMS systems, thick insulation and water treatment has resulted in an ultra-efficient system which, in turn, has significantly lowered the energy bills for the residents.
          As well as the modern boiler room the newly renovated storeroom has provided the residents with an extra room which would of otherwise been impossible.
          </p>
          <StyledButton
            href="https://hdvirtualart.com/tours/bbis/003/"
            variant="primary"
            size="lg"
            target="blank">
              View 360 tour
            </StyledButton>
        </Col>
      </Row>
      <h4 class="sub-heading pt-4">Before</h4>
      <Row>
        <Col md className="pbs">
          <Img
            fluid={data.portlandbefore4.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.portlandbefore5.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.portlandbefore6.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.portlandbefore7.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.portlandbefore8.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
      </Row>
      <h4 class="sub-heading pt-4">During</h4>

      <Row>
      <Col sm className="pbs">
          <Img
            fluid={data.portlandbefore9.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 project-50 mx-auto d-block img-fluid"
          />
        </Col>
        <Col sm className="pbs">
          <Img
            fluid={data.portlandbefore10.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 project-50 mx-auto d-block img-fluid"
          />
        </Col>
        <Col sm className="pbs">
          <Img
            fluid={data.portlandbefore11.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 project-50 mx-auto d-block img-fluid"
          />
        </Col>
        <Col sm className="pbs">
          <Img
            fluid={data.portlandbefore12.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 project-50 mx-auto d-block img-fluid"
          />
          <p>We also generated hot water to the property while the project was being completed.</p>
        </Col>
      </Row>




      <h4 class="sub-heading pt-4">After</h4>
      <Row>
      <Col md className="pbs">
          <Img
            fluid={data.portlandafter.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />

        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.portlandafter1.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
      </Row>
      
      <br></br>
      <Row>
      <Col md className="pbs">
          <Img
            fluid={data.portlandbefore.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.portlandbefore1.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.portlandbefore3.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
      </Row>
    </div>
    <div class="white-segment text-left">
      <h4 class="sub-heading pb-2">Wrotham Park</h4>
      <Row>
        <Col lg>
          <Img
            fluid={data.wrothampark.childImageSharp.fluid}
            className="pb-4 mx-auto d-block img-fluid"
            alt="BBIS-Wrotham-park"
          />
          <h4 class="sub-heading pt-4">Project details</h4>
          <p>
          Decommissioning and removal of redundant boiler plant, pipework & redundant plant within existing boiler room 
          Installation of 3 new condensing boilers c/w fully modulating burners for use with natural gas.
          Pipework insulation within boiler room.
          Automatic controls and control/distribution panel  including distribution, switches, lamps, detectors and equipment control valves.
          Installation of 2 stainless steel calorifiers.
          Painting of plantroom
          </p>
        </Col>
      </Row>
    </div>
    <div class="grey-segment text-left">
      <h4 class="sub-heading pb-2">Wimpole Street, London</h4>
      <Row>
        <Col lg>
          <Img
            fluid={data.wimpole.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
          <h4 class="sub-heading pt-4">Project details</h4>
          <p>
            The old boiler room occupied two rooms and was deigned and built in
            the 1980’s. The plant was removed and a complete new boiler made in
            half the original space. This allowed the other half to be turned in
            to a reception area and washroom facilities.
          </p>
          <p>
            The boiler room was started afresh from a bare room. Careful
            consideration and planning was involved to make sure all plant and
            pipework went in to the available space. Three 115 KW Remeha boilers
            provide the heating, and the hot water is stored in 2 large
            calorifiers.
          </p>
          <p>
            All pipework and flues were fabricated on site and a bespoke control
            panel, was installed to run the boilers, pumps and valves via a
            TREND BMS system.
          </p>
          <StyledButton
            href="https://hdvirtualart.com/tours/bbis/001/"
            variant="primary"
            size="lg"
            target="_blank"
          >
            View 360 tour
          </StyledButton>
        </Col>
      </Row>
    </div>
    <div class="white-segment text-left">
      <h4 class="sub-heading pb-2">London W1</h4>
      <Row>
        <Col md>
          <Img
            fluid={data.london1.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md>
          <Img
            fluid={data.london2.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
      </Row>
      <Row>
        <Col lg>
          <h4 class="sub-heading pt-4">Project details</h4>
          <p>
            This project involved among other things; the removal of two ageing
            boilers and cleaning of the boiler room, new boilers installed and a
            sealed system conversion. The design and fabrication of new
            pipework, a new custom built electrical control panel. Power
            flushing of the entire heating system, dosing of the system with
            inhibitor.
          </p>
          <p>
            The system was commissioned and is now running quieter, more
            efficiently and with greatly improved flow around the building.
          </p>
        </Col>
      </Row>
    </div>
    <div class="grey-segment text-left">
      <h4 class="sub-heading pb-2">Chancellors School</h4>
      <Row>
        <Col md>
          <Img
            fluid={data.cs1.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md>
          <Img
            fluid={data.cs2.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
      </Row>
      <Row>
        <Col lg>
          <h4 class="sub-heading pt-4">Project details</h4>
          <p>
            A service visit comprising of 3 boiler rooms, 3 gas meters and their
            pipework which has to be tested and certificated.
          </p>
          <p>
            A gym hall with radiant overhead gas heaters and various gas
            appliances. There are also 2 gas fired water heaters, expansion
            vessels, pressurisation unit, and various pumps which are all
            maintained by ourselves.
          </p>
        </Col>
      </Row>
    </div>
    <div class="white-segment text-left">
      <h4 class="sub-heading pb-2">Portland Place</h4>
      <Row>
        <Col lg>
          <Img
            fluid={data.portland.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
          <h4 class="sub-heading pt-4">Project details</h4>
          <p>
            Removal of an ageing calorifier and installation of a new
            instantaneous hot water plate heat exchanger (PHE). This plate heat
            exchanger provides hot water for 12 flats with multiple bedrooms and
            bathrooms per residency.
          </p>
          <p>
            The plate heat exchanger removes the need for a storage vessel and
            frees up space within the boiler room, not to mention the reduced
            running costs, due to the high efficiency of the PHE.
          </p>
        </Col>
      </Row>
      <Row>
      <h4 class="text-center">Before</h4>
        <Col sm>
          <Img
            fluid={data.ppBefore.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid project-50"
          />
        </Col>
        <Col sm>
          <Img
            fluid={data.ppAfter.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid project-50"
          />
        </Col>
      </Row>
    </div>
    <div class="grey-segment text-left">
      <h4 class="sub-heading pb-2">Essendon Country Club</h4>
      <Row>
        <Col lg>
          <Img
            fluid={data.ecc.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
          <h4 class="sub-heading ">Project details</h4>
          <p>
            This project involved laying underfloor heating for the clubhouse
            extension. The heat is supplied to the room via approximately 700
            meters of pipes buried under a concrete screed.
          </p>
          <p>
            The pipes are fed from a manifold which in turn is supplied from the
            main boilers. The temperature is regulated by a remote room
            thermostat linked to a pump and motorised valve.
          </p>
          <p>
            Underfloor heating was used to allow maximum seating capacity
            capacity and less visual impact for the customers at Essendon
            Country Club.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md>
          <Img
            fluid={data.eccb.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
          <h4 class="text-center">During</h4>
        </Col>

        <Col md>
          <Img
            fluid={data.ecca.childImageSharp.fluid}
            alt="BBIS-Wrotham-park"
            className="pb-4 mx-auto d-block img-fluid"
          />
          <h4 class="text-center">After</h4>
        </Col>
      </Row>
    </div>
  </Layout>
);

export const query = graphql`
  query ProjectQueries {
    wrothampark: file(relativePath: { eq: "Projects/wrothampark.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    wimpole: file(relativePath: { eq: "Projects/wimpole.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 1137) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    london1: file(relativePath: { eq: "Projects/london-1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    london2: file(relativePath: { eq: "Projects/london-2.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    cs1: file(relativePath: { eq: "Projects/cs-1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    cs2: file(relativePath: { eq: "Projects/cs-2.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portland: file(relativePath: { eq: "Projects/portland.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    ppBefore: file(relativePath: { eq: "Projects/pp-before.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    ppAfter: file(relativePath: { eq: "Projects/pp-after.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    ecc: file(relativePath: { eq: "Projects/ecc.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    eccb: file(relativePath: { eq: "Projects/eccb.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    ecca: file(relativePath: { eq: "Projects/ecca.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandplace86: file(relativePath: { eq: "Projects/portlandplace/devonshiresquare.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse: file(relativePath: { eq: "Projects/marblehouse/marblehouse.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse1: file(relativePath: { eq: "Projects/marblehouse/marblehouse1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse2: file(relativePath: { eq: "Projects/marblehouse/marblehouse2.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse3: file(relativePath: { eq: "Projects/marblehouse/marblehouse3.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse4: file(relativePath: { eq: "Projects/marblehouse/marblehouse4.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse5: file(relativePath: { eq: "Projects/marblehouse/marblehouse5.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse6: file(relativePath: { eq: "Projects/marblehouse/marblehouse6.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    newmarblehillhouse50: file(relativePath: { eq: "Projects/marblehouse/newmarblehillhouse.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandafter: file(relativePath: { eq: "Projects/portlandplace/after.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandafter1: file(relativePath: { eq: "Projects/portlandplace/after1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore: file(relativePath: { eq: "Projects/portlandplace/after2.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore1: file(relativePath: { eq: "Projects/portlandplace/after4.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore3: file(relativePath: { eq: "Projects/portlandplace/after3.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore4: file(relativePath: { eq: "Projects/portlandplace/before.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore5: file(relativePath: { eq: "Projects/portlandplace/before2.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore6: file(relativePath: { eq: "Projects/portlandplace/before3.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore7: file(relativePath: { eq: "Projects/portlandplace/before4.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore8: file(relativePath: { eq: "Projects/portlandplace/before5.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore9: file(relativePath: { eq: "Projects/portlandplace/during.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore10: file(relativePath: { eq: "Projects/portlandplace/during1.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore11: file(relativePath: { eq: "Projects/portlandplace/during2.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portlandbefore12: file(relativePath: { eq: "Projects/portlandplace/temphotwater.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
